/* Switch project for root users */

// todo: make more intuitive; ajax call for listing. Select first on enter. Open on ?ctrl-ctrl? click.
export function sbSwitch() {
    const sb = j$('#sbcontainer');
    if (sb) {
        let bg;
        const sbwidth = sb.width();

        const closeSbSwicher = function (e) {
            e.preventDefault();
            sb.animate({"right": -sbwidth}, 300, "easeOutCubic", function () {
                j$("#sbcontent>div.active").removeClass("active");
                sb.css("right", "");
            });
            if (bg) {
                bg.removeClass('backdrop-active');
                setTimeout(function () {
                    bg.remove();
                }, 300);
            }
        };

        const renderProjects = function () {
            const allProjects = j$('#psallprojects');
            if (!allProjects.data('loaded')) {
                projectSwitcherProjects.forEach(function (project, key) {
                    const li = j$('<li></li>');
                    li.attr('id', 'projectswitch-' + project.id);
                    li.appendTo(allProjects);
                    const link = j$('<a></a>');
                    link.attr('href', project.url);
                    link.text(project.name);
                    link.appendTo(li);
                    const image = j$('<img src="" alt="">');
                    image.attr('src', project.favicon);
                    image.addClass('favicon');
                    image.prependTo(link);

                    projectSwitcherProjects[key].name = project.name.toLowerCase();
                });
                allProjects.data('loaded', true);
            }

            const keywords = j$('#pskeywords').val().toLowerCase().split(' ').filter(Boolean);
            const emptyKeywords = keywords.length === 0;
            projectSwitcherProjects.forEach(function (project) {
                let showItem = true;
                if (!emptyKeywords) {
                    for (var k = 0; k < keywords.length; k++) {
                        if ((project.name + project.server_name).indexOf(keywords[k]) < 0) {
                            showItem = false;
                            break;
                        }
                    }
                }
                j$('#projectswitch-' + project.id).css('display', showItem ? 'block' : 'none');
            });
        };

        const buttons = j$('.sbbutton');
        buttons.each(function () {
            j$(this).click(function (e) {
                e.preventDefault();
                const rval = parseInt(sb.css.right);
                if (rval === 0 && j$("#content-" + j$(this).data('id') + ".active").length > 0) {
                    closeSbSwicher(e);
                } else if (j$("#sbcontent>div.active").length > 0) {
                    j$("#sbcontent>div.active").removeClass("active");
                    j$("#content-" + j$(this).data('id')).addClass('active');
                    if (j$(this).data('id') === 'ps') {
                        renderProjects();
                        setTimeout(function () {
                            j$('#pskeywords').focus();
                        }, 1000);
                    }
                } else {
                    if (j$(this).data('id') === 'ps') {
                        renderProjects();
                        setTimeout(function () {
                            j$('#pskeywords').focus();
                        }, 1000);
                    }

                    bg = j$("<div></div>").addClass("modal-dialog-backdrop").click(closeSbSwicher);
                    j$("body").append(bg);
                    j$("#content-" + j$(this).data('id')).addClass('active');
                    sb.animate({"right": 0}, 300, "easeOutCubic");

                    setTimeout(function () {
                        bg.addClass('backdrop-active');
                    }, 10);
                }
            });
        });

        j$('#sbclose').click(closeSbSwicher);

        j$('#pskeywords').keyup(function (e) {
            renderProjects();
        });
    }
}
