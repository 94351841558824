import './javascripts/jQuery.init.js';
import "jquery-ui/dist/jquery-ui.js";
import "select2/dist/js/i18n/nl.js";
import "@chenfengyuan/datepicker";
import './javascripts/form/password.js';
import * as exports from './javascripts/global.js';
import $ from "jquery";
import {sbSwitch} from "./javascripts/components/layout/sidebar.js";

globalThis.$ = $;
window.$ = $;

// Dirty hack to add all global.js exports to globalThis
Object.entries(exports).forEach(([name, exported]) => globalThis[name] = exported);

// Load sidebar
if (typeof window.projectSwitcherProjects !== "undefined") {
    sbSwitch();
}

console.log("App loaded.");